
import React, { useContext, useState} from "react";
import { graphql, useStaticQuery } from "gatsby";



const DataContext = React.createContext();
const LocaleUpdateContext = React.createContext();


export const useData = () => {
  return useContext(DataContext);
};

export const useLocaleUpdate = () => {
  return useContext(LocaleUpdateContext);
};

export const DataProvider = ({children}) => {

  const data = useStaticQuery(graphql`
  {
    allWpPage {
      edges {
        node {
          title
          show {
            show
          }
          menu {
            menu {
              item
              to
            }
          }
          text {
            maintext
          }
          text2 {
            maintext2
          }
          button {
            name
            url
          }
          mapa {
            list1 {
              listItem
            }
            list2 {
              listItem
            }
            fieldGroupName
            list3 {
              listItem
            }
            title
            list1Title
            list2Title
            list3Title
          }
          agenda {
            card {
              date
              text
            }
          }
          email {
            email
          }
        }
      }
    }
  }
`);
  let [locale, setLocale] = useState("en");

  const useLocaleUpdate = (lang) => {
    setLocale(lang);
  };
  //console.log("locale",locale)
  return (
    <DataContext.Provider value={{locale, data}}>
            <LocaleUpdateContext.Provider value={useLocaleUpdate}>
        {children}
              </LocaleUpdateContext.Provider>
    </DataContext.Provider>
  );
};
