import './src/styles/global.css';
import React from "react"
import { DataProvider } from "./src/components/context/dataContext";
import { CurrentProvider } from "./src/components/context/currentContext";


export const wrapRootElement = ({ element }) => (
  <CurrentProvider>
    <DataProvider>{element}</DataProvider>
  </CurrentProvider> 
)
